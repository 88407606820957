import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/digital-garden-template.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`title: "Digital Garden"
template: "digital-garden"
socialImage: "garden.jpg"
slug: '/digital-garden'
description: "My Digital Garden - a place where I document what I've learned"`}</p>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      